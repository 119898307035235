<template>
  <div :class="['header', { fixed: isFixed }]">
    <img @click.prevent="scrollToTop" class="logo" src="@/assets/svg/logo.svg" alt="Logo">
    <div class="play">
      <Button @click="openCasino" class="button">Казино</Button>
    </div>
    <a @click.prevent="smoothScroll('#casino')" class="menu">О нас</a>
    <a @click.prevent="smoothScroll('#tokenomic')" class="menu">Токеномика</a>
    <a @click.prevent="smoothScroll('#loadmap')" class="menu">План развития</a>
    <a @click.prevent="smoothScroll('#faq')" class="menu">Вопросы</a>
    <Button v-if="!walletAddress" @click="connectWallet" class="button">Подключить кошелек</Button>
    <Button v-if="walletAddress" @click="disconnectWallet" class="button">Отключить кошелек</Button>
    <div v-if="walletAddress">
      Подключен: {{ walletAddress }}
    </div>
  </div>
</template>

<script>
import tonConnectUI from '../plugins/tonconnect.js';

export default {
  data() {
    return {
      walletAddress: null,
      isConnected: false,
      isFixed: false, // Переменная для отслеживания фиксированного состояния
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Плавная прокрутка наверх
    },
    smoothScroll(targetId) {
      const targetElement = document.querySelector(targetId); // Находим элемент по ID
      const headerHeight = document.querySelector('.header').offsetHeight; // Получаем высоту header
      if (targetElement) {
        const offsetTop = targetElement.offsetTop - headerHeight; // Вычисляем позицию с учетом высоты header
        window.scrollTo({ top: offsetTop, behavior: 'smooth' }); // Плавная прокрутка
      }
    },
    async connectWallet() {
      try {
        await tonConnectUI.connectWallet();
        const wallet = tonConnectUI.wallet;
        if (wallet) {
          this.walletAddress = wallet.account.address;
          this.isConnected = true;
        }
      } catch (error) {}
    },
    async disconnectWallet() {
      await tonConnectUI.disconnect();
      this.walletAddress = null;
      this.isConnected = false;
      console.log('Wallet disconnected');
    },
    openCasino() {
      window.open('https://disigames.click', '_blank');
    },
    handleScroll() {
      // Если прокрутка больше 50px, добавляем фиксированное состояние
      this.isFixed = window.scrollY > 50;
    },
  },
  mounted() {
    if (tonConnectUI.wallet) {
      tonConnectUI.wallet.onChange((wallet) => {
        if (wallet) {
          this.walletAddress = wallet.account.address;
        } else {
          this.walletAddress = null;
        }
      });
    }
    // Добавляем обработчик прокрутки
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // Удаляем обработчик прокрутки
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
.header {
  position: fixed; /* Header всегда фиксирован */
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: all 0.3s ease; /* Плавный переход для улучшения эстетики */

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgb(52, 55, 64);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.53);
  }

  .menu {
    margin-left: 25px;
    color: rgb(195, 195, 195);
    cursor: pointer;

    &:hover {
      color: white;
    }

    @media (max-width: 1280px) {
      display: none;
    }
  }
}

.button {
  border-radius: 25px !important;
  margin-left: 25px;
  font-size: 1rem !important;
  color: white;

  @media (max-width: 1280px) {
    font-size: 0.8rem !important;
  }

  @media (max-width: 430px) {
    font-size: 0.6rem !important;
  }
}
.play {
  @media (max-width: 500px) {
    display: none;
  }
}
</style>