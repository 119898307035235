<template>
  <div id="offer" class="offer">
    <div class="left"><span class="purple">Мы сделаем предложение</span> от которого вы не сможете отказаться.
      <p> Начните получать прибыль от действующего онлайн казино и наших iGaming решений вместе с <span class="text-white">$DIC</span> на
        <span class="text-white">The Open Network и Solana</span></p>
      <Button class="mr-3">
        Купить по 0.02142$
      </Button>
      <Button class="p-button-outlined">
        Документация
      </Button>
      <div class="vesting">До окончания Private Sale: 4 месяца</div>
    </div>
    <div class="right">
      <img class="toncoins" src="@/assets/disi-dic.png"/>
    </div>
  </div>
</template>
<style lang="scss">
.offer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
  .left {
    width: 50%;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 38px;
    @media (max-width: 1100px) {
      width: 70%;
    }
    @media (max-width: 600px) {
      width: 100%;
      font-size: 24px;
    }
    .vesting {
      font-size: 14px;
      margin-top: 15px;
      @media (max-width: 450px) {
        font-size: 10px;
      }
    }
  }
  .right {
    width: 50%;
    @media (max-width: 1100px) {
      width: 100%;
      justify-content: center;
      margin: auto;
      display: flex;
    }
    .toncoins {
      width: 100%;
    }
  }
}
</style>
<script setup lang="ts">
</script>