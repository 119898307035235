<template>
  <div id="loadmap" class="loadmap">
    <div class="center">Наша дорожная <span class="purple">карта</span> </div>
    <div class="d-flex">
      <div class="block">
        <Timeline :value="events">
          <template #content="slotProps">
            <div :class="getItemClass(slotProps.index)">
              {{ slotProps.item.status }}
            </div>
          </template>
          <template #opposite="slotProps">
            <div>{{ slotProps.item.date }}</div>
          </template>
          <template #marker="slotProps">
            <i :class="'p-timeline-event-marker ' + getMarkerClass(slotProps.index)"></i>
          </template>
        </Timeline>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loadmap',
  data() {
    return {
      events: [
        { status: 'Начало разработки казино Disi', date: 'Q4 2023' },
        { status: 'Запуск казино Disi', date: '' },
        { status: 'Интеграция казино Disi в Telegram', date: 'Q1 2024' },
        { status: 'Начало разработки $DIC на The Open Network', date: '' },
        { status: 'Начало разработки $DIC на Solana', date: '' },
        { status: 'Интеграция криптовалюты в казино Disi', date: '' },
        { status: 'Интеграция $DIC в игровую платформу', date: 'Q2 2024' },
        { status: 'Выход на страны СНГ', date: '' },
        { status: 'Реализация Pre-farming $DIC', date: '' },
        { status: 'Запуск уникальной игры в Telegram', date: 'Q3 2024' },
        { status: 'Выход на англоязычную аудиторию', date: '' },
        { status: '1 000 000 пользователей', date: 'Q4 2024' },
        { status: 'Готовность к запуску Private Sale', date: '' },
      ]
    };
  },
  methods: {
    getMarkerClass(index) {
      if (index === 0 || index === 2 || index === 1 || index === 3 || index === 3 || index === 3) {
        return 'custom-marker';
      }
      return '';
    },
    getItemClass(index) {
      // Изменяем класс для определенных элементов
      if (index === 0 || index === 2 || index === 1 || index === 3) {
        return 'highlight';
      }
      return '';
    }
  }
};
</script>

<style lang="scss">
.loadmap {
  gap: 40px;
  align-self: stretch;
  .center {
    font-size: 38px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 25px;
    color: white;
    font-weight: bold;
    justify-content: center;
    align-items: center;
  }
  .right {
    width: 50%;
    .toncoins {
      width: 100%;
    }
  }
  .center {
    width: 100%;
    color: white;
    font-weight: 600;
    font-size: 38px;
  }
  .block {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: -6vh;
  }
}

.highlight {
  position: relative;
  color: white;
}
.highlight::after {
  content: "";
  position: absolute;
  top: 11%;
  left: -1.7rem;
  width: 4px;
  height: 90px;
  background-color: #5cb942;
}
.custom-marker::after {
  color: white;
  background-color: #5cb942;
}


</style>