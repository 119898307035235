<template>
  <div id="faq" class="faq">
    <div class="center">Ответы <span class="purple">на ваши вопросы</span> </div>
  <Accordion value="0">
    <AccordionPanel value="0">
      <AccordionHeader>Что представляет собой подлинный DIC (Disi Coin)?</AccordionHeader>
      <AccordionContent>
        <p class="m-0">
          Подлинный $DIC представляет собой токен, который можно использовать, торговать, обменивать и передавать.
        </p>
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel value="1">
      <AccordionHeader>Что значит Root Lockup контракт?</AccordionHeader>
      <AccordionContent>
        <p class="m-0">
          Контракт, способный минтить Promise $DIC при получении подлинных токенов
        </p>
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel value="2">
      <AccordionHeader>Что значит Promise $DIC?</AccordionHeader>
      <AccordionContent>
        <p class="m-0">
          Токен, выданный пользователю как гарантия получения подлинных токенов.
        </p>
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel value="3">
      <AccordionHeader>Что такое Cliff?</AccordionHeader>
      <AccordionContent>
        <p class="m-0">
          Cliff (порог) — это период в который токены заблокированы в смарт-контракте и не начисляться пользователю. Вестинг токенов начинается после окончания Cliff-периода. Длительность данного периода, как и параметры периода вестинга, запрограммированы в смарт-контракте и не могут быть изменены (Верифицированный смарт-контракт)
        </p>
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel value="4">
      <AccordionHeader>Что такое Vesting?</AccordionHeader>
      <AccordionContent>
        <p class="m-0">
          Vesting (наделение) — это термин обозначающий период частично заблокированных в смарт-контракте монет и начисляются пользователю в течении определенного времени (до даты полной разморозки). Вестинг у нас линейный, токены разблокируются равномерно (каждую секунду) в течении периода от начала до конца вестинга.
        </p>
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel value="5">
      <AccordionHeader>Что такое Disi?</AccordionHeader>
      <AccordionContent>
        <p class="m-0">
          Проект Disi Casino представляет собой передовое игровое мультиплатформенное решение, построенное на блокчейн-технологии SOLANA и TON. Основная цель проекта — создание безопасной и прозрачной среды для игроков, где они могут участвовать в азартных играх, используя криптовалюту DIC (Disi Coin). Эта платформа решает важные вопросы, связанные с доверием, безопасностью и ограничениями в индустрии iGaming, предлагая новаторские решения, такие как децентрализация, прозрачная система вознаграждений и интеграция с мессенджером Telegram и другими популярными платформами. Disi стремится занять лидирующую позицию в мире криптовалютных азартных игр, предоставляя исключительный игровой опыт и удовлетворяя потребности пользователей в безопасности и удобстве.
        </p>
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel value="6">
      <AccordionHeader>Что делает проект уникальным?</AccordionHeader>
      <AccordionContent>
        <p class="m-0">
          Проект Disi Casino выделяется среди других игровых платформ благодаря нескольким важным уникальным чертам: <br><br>

          Применение блокчейн-технологии: Disi Casino основан на передовой блокчейн-технологии, которая обеспечивает безопасность и прозрачность игрового процесса, а также создает децентрализованную платформу.<br>
          Собственный токен DIC: Платформа внедряет свою криптовалюту DIC (Disi Coin), предоставляя игрокам безопасный и удобный способ участвовать в азартных играх и получать вознаграждения.<br>
          Интеграция с Telegram: Disi Casino органично интегрирован с мессенджером Telegram, что делает его доступным широкой аудитории пользователей.<br>
          Прозрачная система вознаграждений и NFT: Платформа предлагает инновационную систему вознаграждений для аффилиатов, основанную на блокчейне и NFT.<br>
          Решение проблем доверия и безопасности: Disi Casino устраняет проблемы доверия в индустрии азартных игр, предоставляя честную и безопасную игровую среду, основанную на блокчейн-технологии.<br><br>

          Эти особенности делают Disi Casino уникальным проектом, который стремится изменить индустрию азартных развлечений.
        </p>
      </AccordionContent>
    </AccordionPanel>
    <AccordionPanel value="7">
      <AccordionHeader>Технологии</AccordionHeader>
      <AccordionContent class="content">
        <p class="m-0">
          Disi Casino и его экосистема тесно связаны с передовой технологией блокчейн, которая обеспечивает прозрачность, безопасность и инновационную направленность нашей игровой платформы. Основой нашей экосистемы является токен DIC(Disi Casino), который базируется на блокчейне TON — The Open Network.<br>

          TON - это блокчейн следующего поколения, который приобрел широкую известность благодаря своим уникальным характеристикам:<br><br>

          Скорость и эффективность: TON спроектирован так, чтобы обеспечить высочайшую производительность и неограниченную масштабируемость. Это позволяет Disi Casino обеспечивать для наших пользователей мгновенные и безопасные транзакции.<br><br>

          Защита: TON обеспечивает высокий уровень защиты данных, что становится особенно важно для игровых платформ. Все транзакции и данные на платформе Disi Casino надежно защищены благодаря использованию криптографических алгоритмов.<br><br>

          Децентрализация: Технология TON предоставляет Disi Casino децентрализованную среду, гарантирующую открытость игрового процесса и партнерства.
        </p>
      </AccordionContent>
    </AccordionPanel>
  </Accordion>
  </div>
</template>
<script setup lang="ts">
</script>
<style>
.faq {
  color: white;
  .center {
    font-size: 38px;
    text-align: center;
    margin-bottom: 25px;
    color: white;
    font-weight: bold;
    justify-content: center;
    align-items: center;
  }
}
p {
  color: white;
}
</style>