<template>
  <div id="footer" class="footer">
    <div class="d-flex">
      <div class="left">
        <img class="logo" src="@/assets/svg/logo.svg" alt="Logo">
      </div>
      <div class="right d-flex">
        <a href="">Поддержка</a>
        <a href="https://t.me/disicoin_rus">Telegram RU</a>
        <a href="https://t.me/disicoin_eng">Telegram EN</a>
        <a href="https://t.me/disi_forum">Форум</a>
      </div>
    </div>
    <hr>
    <div class="d-flex">
      <div class="left">
        <div class="">Disapp Development SRL. <br><br>

          Costa Rico, San Jose, Montes de oca, Barrio dent 20</div>
      </div>
      <div class="right d-flex">
        <div class="">© 2024 Disi | Все права защищены.</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss">
.logo {
  width: 80px;
  @media (max-width: 1280px) {
    width: 50%;
    max-width: 100px;
  }
}
.footer {
  padding: 25px;
  padding-top: 100px;
  max-width: 1360px;
  margin: auto;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    display: block;
  }
  .left {
    width: 50%;
    align-content: center;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .right {
    width: 50%;
    gap: 10px;
    font-size: 14px;
    text-align: right;
    align-content: center;
    flex-wrap: wrap;
    justify-content: right;
    display: flex;
    @media (max-width: 991px) {
      width: 100%;
      margin-top: 25px;
      display: block;
    }
  }
}
</style>