<template>
  <div id="casino" class="casino">
    <div class="text-center">Статистика Disi Casino</div>
    <div class="row">
      <div class="block">
        <div class="activity">
          <div class="block-header">Наша активность</div>
          <p class="block-text">В наше казино присоединилось более 1 000 000 пользователей и играют ежедневно более 3000 пользователей.</p>
        </div>
      </div>
      <div class="block">
        <div class="trophey">
          <div class="block-header">Пополняй как угодно</div>
          <p class="block-text">Disi поддерживает огромное количество способов пополнения и вывода средств. Включая криптовалюту и
            фиат.</p>
        </div>
      </div>
      <div class="block">
        <div class="hight">
          <div class="block-header">Мы только растем</div>
          <p class="block-text">Каждый день к нам присоединяется более 1000 новых пользоватей, но нам нужно больше!</p>
        </div>
      </div>
      <div class="block">
        <div class="start">
          <div class="block-header">Это только начало</div>
          <p class="block-text">Наши грандизоные планы изменят iGaming индустрию во всех его направлениях, ведь у нас за плечами огромный
            опыт!</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.casino {
  font-size: 48px;
  scroll-margin-top: 96px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: bold;
  color: white;
  .block {
    width: 48%;
    margin: 10px;
    border-radius: 5px;
    background: #202124;
    @media (max-width: 1010px) {
      width: 100%;
    }
    &-header {
      font-size: 25px;
      font-weight: 500;
    }
    &-text {
      width: 75%;
    }
    .activity {
      background-image: url('@/assets/2.png');
      padding: 40px;
      background-repeat: no-repeat;
      background-size: 175px;
      background-position: right 0 bottom 20%;
    }
    .trophey {
      background-image: url('@/assets/1.png');
      padding: 40px;
      background-repeat: no-repeat;
      background-size: 175px;
      background-position: right 0 bottom 20%;
    }
    .hight {
      background-image: url('@/assets/3.png');
      padding: 40px;
      background-repeat: no-repeat;
      background-size: 175px;
      background-position: right 0 bottom 20%;
    }
    .start {
      background-image: url('@/assets/4.png');
      padding: 40px;
      background-repeat: no-repeat;
      background-size: 175px;
      background-position: right 0 bottom 20%;
    }
  }
}
</style>
<script setup lang="ts">
</script>