<template>
  <div id="tokenomic" class="tokenomic">
    <div class="center">Наша <span class="purple">токеномика</span> </div>
    <div class="statsCoin">
      <div class="blockCoin">$DIC
        <div>Тикер</div>
      </div>
      <div class="blockCoin">0.14B
        <div>Токенов</div>
      </div>
      <div class="blockCoin">$0.02$
        <div>Private Sale цена</div>
      </div>
      <div class="blockCoin">....
        <div>Presale цена</div>
      </div>
      <div class="blockCoin">....
        <div>Public Sale цена</div>
      </div>
      <div class="blockCoin">....
        <div>Листинг</div>
      </div>
      <div class="blockCoin">Блокчейн
        <div>SOL / TON</div>
      </div>
    </div>
    <div class="d-md-flex">
      <div class="left">
        <div class="asideLeft"
             @mouseover="setImage(5)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Private Sale</div>
            <div class="asideLeft-text">Линейный вестинг: 8.33% каждый месяц после клиффа.</div>
          </div>
          <div class="percent">
            20%
          </div>
          <div class="total">
            28M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(6)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Presale sale</div>
            <div class="asideLeft-text">Итоговый вестинг: по 25% каждые 3 месяца. (12 мес.)</div>
          </div>
          <div class="percent">
            2%
          </div>
          <div class="total">
            2.8M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(7)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Public sale</div>
            <div class="asideLeft-text">Линейный вестинг 8.33% каждый мес. (12+3 мес.)</div>
          </div>
          <div class="percent">
            8%
          </div>
          <div class="total">
            11.2M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(8)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Treasury</div>
            <div class="asideLeft-text">Клифф: 6 месяцев. Анлок через 6 мес. 5% токенов каждые 3 мес.</div>
          </div>
          <div class="percent">
            5%
          </div>
          <div class="total">
            7M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(9)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Marketing</div>
            <div class="asideLeft-text">10% анлок при TGE и 5% ежемесячный анлок (18 мес.)</div>
          </div>
          <div class="percent">
            12%
          </div>
          <div class="total">
            16.8M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(10)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Team</div>
            <div class="asideLeft-text">6 мес. клифф., 5% линейный вестинг на 20 мес. (20+6 мес.)</div>
          </div>
          <div class="percent">
            10%
          </div>
          <div class="total">
            14M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(1)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Airdrop & Community Incentives</div>
            <div class="asideLeft-text">50% анлок при TGE; 50% анлок после 6 мес. (6 мес.)</div>
          </div>
          <div class="percent">
            16%
          </div>
          <div class="total">
            22.4M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(2)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Staking & Farming <rewards></rewards></div>
            <div class="asideLeft-text">35% анлок при TGE; 25% анлок через 3 мес., остаток анлок 10% каждые 4 мес. (3+4 мес.) </div>
          </div>
          <div class="percent">
            10%
          </div>
          <div class="total">
            14M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(3)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Liquidity (DEX and CEX)</div>
            <div class="asideLeft-text">20% анлок при TGE и 10% ежемесячный анлок (8 мес.)</div>
          </div>
          <div class="percent">
            11%
          </div>
          <div class="total">
            15.4M
          </div>
        </div>
        <div class="asideLeft"
             @mouseover="setImage(4)"
             @mouseleave="resetImage">
          <div class="info">
            <div class="asideLeft-header">Staking & Farming
            </div>
            <div class="asideLeft-text">20% анлок при TGE и линейный вестинг 4% в мес. (20 мес.)</div>
          </div>
          <div class="percent">
            6%
          </div>
          <div class="total">
            8.4M
          </div>
        </div>
      </div>
        <div class="right">
          <img :class="{'circle': true, 'visble': activeImage === 0, 'n-visible': activeImage !== 0}" src="@/assets/tokenomic-default.png">
          <img :class="{'circle': true, 'visble': activeImage === 1, 'n-visible': activeImage !== 1}" src="@/assets/tokenomic-1.png">
          <img :class="{'circle': true, 'visble': activeImage === 2, 'n-visible': activeImage !== 2}" src="@/assets/tokenomic-2.png">
          <img :class="{'circle': true, 'visble': activeImage === 3, 'n-visible': activeImage !== 3}" src="@/assets/tokenomic-3.png">
          <img :class="{'circle': true, 'visble': activeImage === 4, 'n-visible': activeImage !== 4}" src="@/assets/tokenomic-4.png">
          <img :class="{'circle': true, 'visble': activeImage === 5, 'n-visible': activeImage !== 5}" src="@/assets/tokenomic-5.png">
          <img :class="{'circle': true, 'visble': activeImage === 6, 'n-visible': activeImage !== 6}" src="@/assets/tokenomic-6.png">
          <img :class="{'circle': true, 'visble': activeImage === 7, 'n-visible': activeImage !== 7}" src="@/assets/tokenomic-7.png">
          <img :class="{'circle': true, 'visble': activeImage === 8, 'n-visible': activeImage !== 8}" src="@/assets/tokenomic-8.png">
          <img :class="{'circle': true, 'visble': activeImage === 9, 'n-visible': activeImage !== 9}" src="@/assets/tokenomic-9.png">
          <img :class="{'circle': true, 'visble': activeImage === 10, 'n-visible': activeImage !== 10}" src="@/assets/tokenomic-10.png">
        </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.tokenomic {
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 40px;
  align-self: stretch;
  .statsCoin {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    .blockCoin {
      padding: 15px;
      background-color: #24272c;
      border-radius: 15px;
      width: 100%;
      text-align: center;
      @media (min-width: 1200px) {
        width: 13.3%;
      }
    }
  }
  .center {
    font-size: 38px;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
  }
  .left {
    width: 50%;
    background: #24272c;
    color: white;
    font-weight: 600;
    border-radius: 15px 0 0 15px;
    margin-top: 10px;
    font-size: 38px;
    @media (max-width: 992px) {
      padding: 5px;
      width: 100%;
    }
    .asideLeft {
      background: #363942;
      cursor: pointer;
      display: flex;
      padding: 8px;
      margin: 10px;
      border-radius: 5px;
      &:hover {
        background: #7eae36;
      }
      &-header {
        font-size: 15px;
      }
      &-text {
        font-size: 12px;
        font-weight: 400;
      }
      .info {
        width: 80%;
      }
      .percent {
        width: 10%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 430px) {
          font-size: 8px;
        }
      }
      .total {
        width: 10%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 430px) {
          font-size: 8px;
        }
      }
    }
  }
  .right {
    width: 50%;
    background: #24272c;
    color: white;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    display: flex;
    border-radius: 0 15px 15px 0;
    margin-top: 10px;
    @media (max-width: 992px) {
      width: 100%;
    }
    .toncoins {
      width: 100%;
    }
  }
  .center {
    width: 100%;
    color: white;
    font-weight: 600;
    font-size: 38px;
    &-aside {
      display: flex;
      @media (max-width: 850px) {
        flex-wrap: wrap;
      }
    }
    .block {
      font-size: 20px;
      width: 20%;
      padding: 25px;
      background: #24272c;
      text-align: center;
      margin: 10px;
      border-radius: 15px;
      @media (max-width: 850px) {
        font-size: 28px;
        width: 100%;
      }
      p {
        font-size: 14px;
        font-weight: 200;
      }
    }
  }
  .circle {
    max-width: 438px;
    @media (max-width: 992px) {
      width: 100%;
      padding: 25px;
    }
    &:hover {
      display: block;
    }
  }
}
.visble {
  display: block;
}
.n-visible {
  display: none;
}
</style>
<script>
export default {
  data() {
    return {
      activeImage: 0 // Индекс активного изображения
    }
  },
  methods: {
    setImage(index) {
      this.activeImage = index;
    },
    resetImage() {
      this.activeImage = 0; // Сброс на изображение по умолчанию
    }
  }
}
</script>